import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EcmUrls } from '@core/constants/endpoints.constants';
import { ApiClient, FileWithName } from '@core/services/api-client.http.service';
import {
  EcmFileDetailsDto,
  EcmFilesDto,
  EcmFoldersDto,
  FolderDto,
  PaginatedResponseDto,
  ProductTypeDto
} from '@core/services/dto';
import { ApplicationDetailsDto } from '../dto/applicationdetails.dto';
import { FileUploadResponse } from '../dto/file-upload-response.dto';

@Injectable({
  providedIn: 'any'
})
export class EcmApiService {

  constructor(private api: ApiClient) {
  }

  deleteInstance(instance: any, includedFileIds: any): Observable<any> {
    if (instance.isFile) {
      return this.api.delete<any>(EcmUrls.files, includedFileIds);
    } else {
      return this.api.delete<any>(EcmUrls.folders, includedFileIds);
    }
  }
  archiveFile(instance: any): Observable<any> {
    return this.api.put<any>(EcmUrls.archivefiles, instance);
  }
  restoreFile(instance: any): Observable<any> {
    return this.api.put<any>(EcmUrls.archivefiles, instance);
  }
  deleteFiles(includedFileIds): Observable<any> {
    return this.api.delete<any>(EcmUrls.files, includedFileIds);
  }

  deleteFolder(ids: any): Observable<any> {
    return this.api.delete<any>(EcmUrls.folders, { ids });
  }

  deleteFolders(includedFoldersIds): Observable<any> {
    return this.api.delete<any>(EcmUrls.folders, { includedFoldersIds });
  }

  saveFile(values): Observable<FileUploadResponse> {
    return this.api.post<FileUploadResponse>(EcmUrls.files, values);
  }

  updateFile(id, file): Observable<EcmFileDetailsDto> {
    return this.api.put<EcmFileDetailsDto>(EcmUrls.fileById(id), file);
  }

  getFile(id): Observable<EcmFileDetailsDto> {
    return this.api.get<EcmFileDetailsDto>(EcmUrls.fileById(id));
  }

  getFilesSummary(memberId): Observable<{ dateUploaded: string }> {
    return this.api.get<{ dateUploaded: string }>(EcmUrls.filesSummary, { memberId });
  }

  getTableData(request): Observable<PaginatedResponseDto<EcmFoldersDto>> {
    return this.api.get<PaginatedResponseDto<EcmFoldersDto>>(EcmUrls.folderContent, request);
  }

  getFiles(request): Observable<PaginatedResponseDto<EcmFilesDto>> {
    return this.api.get<PaginatedResponseDto<EcmFilesDto>>(EcmUrls.files, request);
  }

  createFolder(request): Observable<number> {
    return this.api.post<number>(EcmUrls.folders, request);
  }

  getFolder(id): Observable<FolderDto> {
    return this.api.get<FolderDto>(EcmUrls.folderById(id));
  }

  getTypes(): Observable<PaginatedResponseDto<ProductTypeDto>> {
    return this.api.get<PaginatedResponseDto<ProductTypeDto>>(EcmUrls.types);
  }

  getSubTypes(fileTypeId): Observable<PaginatedResponseDto<ProductTypeDto>> {
    return this.api.get<PaginatedResponseDto<ProductTypeDto>>(EcmUrls.getSubTypes, { fileTypeId });
  }

  saveFolder(id, request): Observable<number> {
    return this.api.put<number>(EcmUrls.folderById(id), request);
  }

  downloadFile(includedFileMetadataIds): Observable<FileWithName> {
    return this.api.getFileWithName(EcmUrls.loadFile, null, includedFileMetadataIds);
  }
  getApplicationId(request): Observable<ApplicationDetailsDto>{
    return this.api.get<ApplicationDetailsDto>(EcmUrls.applicationDetails, request);
  }
}

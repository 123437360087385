export enum PermissionEnum {
  AosAdmin = -1,
  ViewConversations = 1,
  AddConversation = 2,
  EditConversation = 3,
  ViewNotes = 4,
  AddNote = 5,
  ViewAllTasks = 6,
  AddTask = 7,
  EditTask = 8,
  ManageEmailTemplates = 9,
  ManageTaskTemplates = 10,
  ManageWorkflowTemplates = 11,
  ViewMembers = 12,
  ViewProfileSummary = 13,
  ViewProfile = 14,
  EditProfile = 15,
  ViewContact = 16,
  EditContact = 17,
  ViewAccounts = 18,
  ManageUsers = 19,
  ImportOpportunities = 20,
  CreateSale = 21,
  DeleteConversation = 22,
  EditAllSales = 23,
  ViewAllSales = 24,
  DeleteAllSales = 25,
  ViewMySales = 26,
  EditMySales = 27,
  DeleteMySales = 28,
  ManageCampaigns = 29,
  EditNote = 30,
  DeleteNote = 31,
  ManageAnnouncements = 32,
  ManagePublicLinks = 33,
  ManageUserLicense = 34,
  ViewLicenseReport = 35,
  ViewReports = 36,
  ThemeBuilder = 37,
  SQLBuilder = 38,
  ECMAdmin = 39,
  ECMUser = 40,
  ManageTags = 41,
  ManageOfferTemplates = 42,
  AddOffer = 43,
  EditOffer = 44,
  ViewOffer = 45,
  ImportOffers = 46,
  OfferActivityReport = 50,
  ViewProspectProfile = 47,
  ManageProspect = 48,
  DeleteProspect = 49,
  AddModels = 51,
  ViewModels = 52,
  EditModels = 53,
  BatchAssignmentForCRM = 54,
  PermanentlyDeleteFiles = 55,
  ConfigureOpportunityEngineTemplates = 56,
  BatchAssignLinkedItems = 58,
  ConfigureQueryScheduling = 60
}

export enum PermissionStringEnum {
  AosAdmin = 'AosAdmin',
  ViewConversations = 'ViewConversations',
  AddConversation = 'AddConversation',
  EditConversation = 'EditConversation',
  ViewNotes = 'ViewNotes',
  AddNote = 'AddNote',
  ViewAllTasks = 'ViewAllTasks',
  AddTask = 'AddTask',
  EditTask = 'EditTask',
  ManageEmailTemplates = 'ManageEmailTemplates',
  ManageTaskTemplates = 'ManageTaskTemplates',
  ManageWorkflowTemplates = 'ManageWorkflowTemplates',
  ViewMembers = 'ViewMembers',
  ViewProfileSummary = 'ViewProfileSummary',
  ViewProfile = 'ViewProfile',
  EditProfile = 'EditProfile',
  ViewContact = 'ViewContact',
  EditContact = 'EditContact',
  ViewAccounts = 'ViewAccounts',
  ManageUsers = 'ManageUsers',
  ImportOpportunities = 'ImportOpportunities',
  CreateSale = 'CreateSale',
  DeleteConversation = 'DeleteConversation',
  EditAllSales = 'EditAllSales',
  ViewAllSales = 'ViewAllSales',
  DeleteAllSales = 'DeleteAllSales',
  ViewMySales = 'ViewMySales',
  EditMySales = 'EditMySales',
  DeleteMySales = 'DeleteMySales',
  ManageCampaigns = 'ManageCampaigns',
  EditNote = 'EditNote',
  DeleteNote = 'DeleteNote',
  ManageAnnouncements = 'ManageAnnouncements',
  ManagePublicLinks = 'ManagePublicLinks',
  ManageUserLicense = 'ManageUserLicense',
  ViewLicenseReport = 'ViewLicenseReport',
  ViewReports = 'ViewReports',
  ThemeBuilder = 'ThemeBuilder',
  SQLBuilder = 'SQLBuilder',
  ECMAdmin = 'ECMAdmin',
  ECMUser = 'ECMUser',
  ManageTags = 'ManageTags',
  ManageOfferTemplates = 'ManageOfferTemplates',
  AddOffer = 'AddOffer',
  EditOffer = 'EditOffer',
  ViewOffer = 'ViewOffer',
  ImportOffers = 'ImportOffers',
  OfferActivityReport = 'OfferActivityReport',
  ViewProspectProfile = 'ViewProspectProfile',
  ManageProspect = 'ManageProspect',
  DeleteProspect = 'DeleteProspect',
  AddModels = 'AddModels',
  ViewModels = 'ViewModels',
  EditModels = 'EditModels',
  BatchAssignmentForCRM = 'BatchAssignmentForCRM',
  PermanentlyDeleteFiles = 'PermanentlyDeleteFiles',
  ConfigureOpportunityEngineTemplates = 'ConfigureOpportunityEngineTemplates',
  ConfigureQueryScheduling = 'ConfigureQueryScheduling',
  BatchAssignLinkedItems = 'BatchAssignLinkedItems'
}

import { RoleEnum } from '@shared/enums/role.enum';
import { CRM_ROLES } from '@shared/constants/roles';
import { getClientToken } from '@core/helpers/token-parser.helper';

const defaultApiBase = `https://asappcrmdev.azurewebsites.net`;
const defaultAsappApiService = `https://asappapiservicedev.azurewebsites.net`;
const defaultMemberBase = `https://asappcrmmemberdev.azurewebsites.net`;
const defaultEmailBase = `https://asappcrmemailservicedev.azurewebsites.net`;
const defaultAddressSearchBase =
  'https://asappcrmaddresssearchservicedev.azurewebsites.net';
const defaultCurrentUserManagementBase =
  'https://asappusermanagementservicedev.azurewebsites.net';
const defaultReportingBase =
  'https://asappreportingservicedev.azurewebsites.net';
const defaultEcmBase = 'https://asappemcservicedev.azurewebsites.net';
const defaultKeyVaultBase =
  'https://asappcrmfunctionsdev.azurewebsites.net/api/AzureKeyVaultFunc';
const defaultBrandingBaseUrl = `https://asappdev.blob.core.windows.net/themes/`;
const uatBrandingBaseUrl = `https://asappuatstorage.blob.core.windows.net/themes/`;
const stagingBrandingBaseUrl = `https://asappstgcrmdata.blob.core.windows.net/themes/`;
const prodBrandingBaseUrl =
  'https://tandiaprodcrmdata.blob.core.windows.net/themes/';
const defaultVersioningBase =
  'https://asapp-dev-versionservice-app.azurewebsites.net';
const defaultOfferBase = 'https://asappofferservicedev.azurewebsites.net';
const defaultLendingDecisioningBase =
  'https://asapp-dev2-lendingdecisioningservice-app.azurewebsites.net';
export const org = {
  localhost: {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-dev2-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-dev2-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-dev2-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-dev2-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-dev2-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-dev2-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'Kwb5h4VldUpvVEatV2TmTZ53C1u94pCyQrTPyvQgwpSzAzFu1A0ouA==',
    reportingBase: 'https://asapp-dev2-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-dev2-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-dev2-ecmservice-app.azurewebsites.net',
    OfferBase: 'https://asapp-dev2-offerservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase: defaultLendingDecisioningBase,
  },
  'tandia.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },

  '1stchoicesavings.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },

  'lakeview.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },

  'cvcu.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },

  'tpcu.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },

  'duca.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },

  'encompass.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },

  'ncu.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },

  'pccu.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },

  'prospera.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },

  'steinbach.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },

  'digitalsavings.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },

  'luminus.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },

  'tandia.portal.staging.asappbanking.com': {
    themeUrl: stagingBrandingBaseUrl,
    apiBase: 'https://asapp-staging-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-staging-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-staging-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-staging-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-staging-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-staging-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'cYTaMblXmgZMso0TthyLV6cbXLYJuPtIm2WfXuPR6tJtAzFuzxX_4A==',
    reportingBase:
      'https://asapp-staging-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-staging-crm-funtionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-staging-ecmservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-staging-lendingdecisioningservice-app.azurewebsites.net',
    OfferBase: 'https://asapp-staging-offerservice-app.azurewebsites.net',
  },

  'leaguedata.ld.portal.test.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
  },

  'leaguedata.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'acadiancreditu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'advancesavings.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'bayviewnb.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'beaubear.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'blackvillecu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'caissepopclare.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'cbcu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'ccunl.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'coastalfinancial.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'communitycreditunion.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'consolidatedcreditu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'cua.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'bcufinancial.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    // OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'dominioncreditunion.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'eaglerivercu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'eastcoastcu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'easternedgecu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'eccu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'gbccu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'hscunl.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'inovacreditunion.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'lahaverivercreditunion.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'lecu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'malpequebaycreditu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'morellcreditu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'nbtacu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'newrosscreditunion.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'newwaterfordcreditunion.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'nlcu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'northsydneycreditunion.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'omista.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'princesscreditunion.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'progressivecu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'provincialcu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'provincialemployees.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'pscu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'reddyk.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'souriscreditu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'stjosephscreditu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'sydneycreditunion.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'teachersplus.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'thecreditu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'tignishcreditu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'valleycreditunion.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'venturecu.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'victorycreditunion.ld.portal.uat.asappbanking.com': {
    themeUrl: `https://lduatcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://ld-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://ld-uat-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://ld-uat-apiservice-app.azurewebsites.net',
    azureFunctionKey:
      'LVVjfz8anyehOk_0AZO0OOkuu3tOQhHz8llS8lQjvCwcAzFusS3NJQ==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-uat-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://leaguedata-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'asappteamportaluidev.azurewebsites.net': {
    themeUrl: defaultBrandingBaseUrl,
    apiBase: defaultApiBase,
    memberBase: defaultMemberBase,
    emailBase: defaultEmailBase,
    addressSearchBase: defaultAddressSearchBase,
    userManagementBase: defaultCurrentUserManagementBase,
    asappApiService: defaultAsappApiService,
    keyVaultBase: defaultKeyVaultBase,
    reportingBase: defaultReportingBase,
    ecmBase: defaultEcmBase,
    versioningBase: defaultVersioningBase,
    azureFunctionKey:
      'ctHgI9Html5DnW4CdwqLLL/oMj6XkIoDhYt4xZSTGU1nzJc/q/vJ0Q==',
  },
  'tandia.portal.qa.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-test-v21-crm-app-hecjcwfue3fbdjg2.canadaeast-01.azurewebsites.net',
    memberBase: 'https://asapp-test-v21-memberservice-app-hkbmb4bfe3hvckff.canadaeast-01.azurewebsites.net',
    emailBase: 'https://asapp-test-v21-emailservice-app-eraseuc3fmbqghes.canadaeast-01.azurewebsites.net',
    addressSearchBase:
      'https://asapp-test-v21-addresssearchservice-app-e6h9g8agf8aga6g2.canadaeast-01.azurewebsites.net',
    userManagementBase:
      'https://asapp-test-v21-usermanagementservice-app-cahzcpcbecd6c4c5.canadaeast-01.azurewebsites.net',
    asappApiService: 'https://asapp-test-v21-asappservice-app-dpemb7fmezdsfrgq.canadaeast-01.azurewebsites.net',
    azureFunctionKey:
      'uH29pRjaZNaQEt5YHdZTgmMJlS6PTz9q3AA8kVbpcAnXAzFuEm8q_w==',
    reportingBase:
      'https://asapp-test-v21-reportingservice-app-huhndubgb5e2guf4.canadaeast-01.azurewebsites.net',
    keyVaultBase:
      'https://asapp-test-v21-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-test-v21-ecmservice-app-amfxdnecdaf0hcer.canadaeast-01.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-test-v21-lendingdecisionservice-app-e0bqdjc5dngtgqac.canadacentral-01.azurewebsites.net',
    OfferBase: 'https://asapp-test-v21-offerservice-app-fkgzcehhfyhfctd6.canadaeast-01.azurewebsites.net',
  },
  '1stchoicesavings.portal.qa.asappbanking.com': {
    themeUrl: defaultBrandingBaseUrl,
    apiBase: defaultApiBase,
    memberBase: defaultMemberBase,
    emailBase: defaultEmailBase,
    addressSearchBase: defaultAddressSearchBase,
    userManagementBase: defaultCurrentUserManagementBase,
    asappApiService: defaultAsappApiService,
    keyVaultBase: defaultKeyVaultBase,
    reportingBase: defaultReportingBase,
    ecmBase: defaultEcmBase,
    azureFunctionKey:
      'ctHgI9Html5DnW4CdwqLLL/oMj6XkIoDhYt4xZSTGU1nzJc/q/vJ0Q==',
  },

  'cvcu.portal.qa.asappbanking.com': {
    themeUrl: defaultBrandingBaseUrl,
    apiBase: defaultApiBase,
    memberBase: defaultMemberBase,
    emailBase: defaultEmailBase,
    addressSearchBase: defaultAddressSearchBase,
    userManagementBase: defaultCurrentUserManagementBase,
    asappApiService: defaultAsappApiService,
    keyVaultBase: defaultKeyVaultBase,
    reportingBase: defaultReportingBase,
    ecmBase: defaultEcmBase,
    azureFunctionKey:
      'ctHgI9Html5DnW4CdwqLLL/oMj6XkIoDhYt4xZSTGU1nzJc/q/vJ0Q==',
  },

  'tandiaportal.asappbanking.com': {
    themeUrl: prodBrandingBaseUrl,
    apiBase: 'https://tandia-prod-crm-app.azurewebsites.net',
    memberBase: 'https://tandia-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://tandia-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://tandia-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://tandia-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://tandia-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://tandia-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://tandia-prod-ecmservice-app.azurewebsites.net',
    reportingBase: 'https://tandia-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      'qdtHg2K7MivGNnMefWg_tXz4l0PyZ9AOpbPCFQI0W_GyAzFu_Uar1A==',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://tandia-prod-offerservice-app.azurewebsites.net',
    LeandingDecisioningBase:
      'https://tandia-prod-lendingdecisioningservice-app.azurewebsites.net',
  },

  'directcuportal.asappbanking.com': {
    themeUrl: prodBrandingBaseUrl,
    apiBase: 'https://tandia-prod-crm-app.azurewebsites.net',
    memberBase: 'https://tandia-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://tandia-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://tandia-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://tandia-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://tandia-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://tandia-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: defaultEcmBase,
    reportingBase: 'https://tandia-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      'qdtHg2K7MivGNnMefWg_tXz4l0PyZ9AOpbPCFQI0W_GyAzFu_Uar1A==',
    LeandingDecisioningBase:
      'https://tandia-prod-lendingdecisioningservice-app.azurewebsites.net',
  },

  'portal.lvcu.ca': {
    themeUrl: prodBrandingBaseUrl,
    apiBase: 'https://tandia-prod-crm-app.azurewebsites.net',
    memberBase: 'https://tandia-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://tandia-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://tandia-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://tandia-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://tandia-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://tandia-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://tandia-prod-ecmservice-app.azurewebsites.net',
    reportingBase: 'https://tandia-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      'qdtHg2K7MivGNnMefWg_tXz4l0PyZ9AOpbPCFQI0W_GyAzFu_Uar1A==',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://tandia-prod-lendingdecisioningservice-app.azurewebsites.net',
    // OfferBase: 'https://tandia-prod-offerservice-app.azurewebsites.net',
  },

  'portal.1stchoicesavings.ca': {
    themeUrl: prodBrandingBaseUrl,
    apiBase: 'https://tandia-prod-crm-app.azurewebsites.net',
    memberBase: 'https://tandia-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://tandia-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://tandia-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://tandia-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://tandia-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://tandia-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://tandia-prod-ecmservice-app.azurewebsites.net',
    reportingBase: 'https://tandia-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      'qdtHg2K7MivGNnMefWg_tXz4l0PyZ9AOpbPCFQI0W_GyAzFu_Uar1A==',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://tandia-prod-lendingdecisioningservice-app.azurewebsites.net',
    // OfferBase: 'https://tandia-prod-offerservice-app.azurewebsites.net',
  },

  'engage.cvcu.bc.ca': {
    themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
    apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    reportingBase: 'https://asapp-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-prod-lendingdecisioningservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },

  'portal.northerncu.com': {
    themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
    apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    reportingBase: 'https://asapp-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },

  'engage.scu.mb.ca': {
    themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
    apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    reportingBase: 'https://asapp-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },

  'portal.pccu.ca': {
    themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
    apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    reportingBase: 'https://asapp-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },

  'manage.duca.com': {
    themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
    apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    reportingBase: 'https://asapp-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-prod-lendingdecisioningservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },

  'asapp.tpcu.on.ca': {
    themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
    apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    reportingBase: 'https://asapp-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-prod-lendingdecisioningservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },

  'portal.luminusfinancial.com': {
    themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
    apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-prod-ecmservice-app.azurewebsites.net',
    reportingBase: 'https://asapp-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },

  'portal.sudburycu.com': {
    themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
    apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-prod-ecmservice-app.azurewebsites.net',
    reportingBase: 'https://asapp-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },

  'portal.encompasscu.ca': {
    themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
    apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    reportingBase: 'https://asapp-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
  },

  'asapp.prospera.ca': {
    themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
    apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    reportingBase: 'https://asapp-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },

  'asapp-prod-teamportal-app.azurewebsites.net': {
    themeUrl: prodBrandingBaseUrl,
    apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://tandia-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: '',
    keyVaultBase:
      'https://tandia-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    reportingBase: '',
    azureFunctionKey:
      'qdtHg2K7MivGNnMefWg_tXz4l0PyZ9AOpbPCFQI0W_GyAzFu_Uar1A==',
  },

  'acadiancreditu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'advancesavings.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'bayviewnb.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'beaubear.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'blackvillecu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'caissepopclare.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'cbcu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'ccunl.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'coastalfinancial.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'communitycreditunion.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'consolidatedcreditu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'cua.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'dominioncreditunion.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'eaglerivercu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'eastcoastcu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'easternedgecu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'eccu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'gbccu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'hscunl.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'inovacreditunion.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'lahaverivercreditunion.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'lecu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'malpequebaycreditu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'morellcreditu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'nbtacu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'newrosscreditunion.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'newwaterfordcreditunion.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'nlcu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'northsydneycreditunion.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'omista.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'princesscreditunion.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'progressivecu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'provincialcu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'provincialemployees.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'pscu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'reddyk.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'souriscreditu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'stjosephscreditu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'sydneycreditunion.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'teachersplus.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'thecreditu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'tignishcreditu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'valleycreditunion.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'venturecu.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'victorycreditunion.ldasapp.com': {
    themeUrl: `https://ldprodcrmdata.blob.core.windows.net/themes/`,
    apiBase: 'https://leaguedata-prod-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-prod-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'ifi6gPpFQDO_fiyEAgFmzcfrKKK-i5mUawT3LoHCtl0CAzFutjjxpw==',
    reportingBase: '',
    keyVaultBase:
      'https://ld-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'steinbach.portal.staging.asappbanking.com': {
    themeUrl: stagingBrandingBaseUrl,
    apiBase: 'https://asapp-staging-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-staging-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-staging-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-staging-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-staging-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-staging-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'cYTaMblXmgZMso0TthyLV6cbXLYJuPtIm2WfXuPR6tJtAzFuzxX_4A==',
    reportingBase:
      'https://asapp-staging-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-staging-crm-funtionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-staging-ecmservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-staging-lendingdecisioningservice-app.azurewebsites.net',
    OfferBase: 'https://asapp-staging-offerservice-app.azurewebsites.net',
  },
  'pccu.portal.staging.asappbanking.com': {
    themeUrl: stagingBrandingBaseUrl,
    apiBase: 'https://asapp-staging-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-staging-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-staging-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-staging-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-staging-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-staging-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'cYTaMblXmgZMso0TthyLV6cbXLYJuPtIm2WfXuPR6tJtAzFuzxX_4A==',
    reportingBase:
      'https://asapp-staging-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-staging-crm-funtionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-staging-ecmservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-staging-lendingdecisioningservice-app.azurewebsites.net',
    OfferBase: 'https://asapp-staging-offerservice-app.azurewebsites.net',
  },
  'tpcu.portal.staging.asappbanking.com': {
    themeUrl: stagingBrandingBaseUrl,
    apiBase: 'https://asapp-staging-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-staging-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-staging-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-staging-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-staging-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-staging-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'cYTaMblXmgZMso0TthyLV6cbXLYJuPtIm2WfXuPR6tJtAzFuzxX_4A==',
    reportingBase:
      'https://asapp-staging-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-staging-crm-funtionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-staging-ecmservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-staging-lendingdecisioningservice-app.azurewebsites.net',
    OfferBase: 'https://asapp-staging-offerservice-app.azurewebsites.net',
  },
  'prospera.portal.staging.asappbanking.com': {
    themeUrl: stagingBrandingBaseUrl,
    apiBase: 'https://asapp-staging-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-staging-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-staging-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-staging-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-staging-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-staging-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'cYTaMblXmgZMso0TthyLV6cbXLYJuPtIm2WfXuPR6tJtAzFuzxX_4A==',
    reportingBase:
      'https://asapp-staging-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-staging-crm-funtionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-staging-ecmservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-staging-lendingdecisioningservice-app.azurewebsites.net',
    OfferBase: 'https://asapp-staging-offerservice-app.azurewebsites.net',
  },
  'lakeview.portal.staging.asappbanking.com': {
    themeUrl: stagingBrandingBaseUrl,
    apiBase: 'https://asapp-staging-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-staging-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-staging-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-staging-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-staging-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-staging-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'cYTaMblXmgZMso0TthyLV6cbXLYJuPtIm2WfXuPR6tJtAzFuzxX_4A==',
    reportingBase:
      'https://asapp-staging-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-staging-crm-funtionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-staging-ecmservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-staging-lendingdecisioningservice-app.azurewebsites.net',
    OfferBase: 'https://asapp-staging-offerservice-app.azurewebsites.net',
  },
  'duca.portal.staging.asappbanking.com': {
    themeUrl: stagingBrandingBaseUrl,
    apiBase: 'https://asapp-staging-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-staging-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-staging-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-staging-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-staging-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-staging-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'cYTaMblXmgZMso0TthyLV6cbXLYJuPtIm2WfXuPR6tJtAzFuzxX_4A==',
    reportingBase:
      'https://asapp-staging-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-staging-crm-funtionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-staging-ecmservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-staging-lendingdecisioningservice-app.azurewebsites.net',
    OfferBase: 'https://asapp-staging-offerservice-app.azurewebsites.net',
  },
  'ncu.portal.staging.asappbanking.com': {
    themeUrl: stagingBrandingBaseUrl,
    apiBase: 'https://asapp-staging-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-staging-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-staging-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-staging-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-staging-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-staging-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'cYTaMblXmgZMso0TthyLV6cbXLYJuPtIm2WfXuPR6tJtAzFuzxX_4A==',
    reportingBase:
      'https://asapp-staging-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-staging-crm-funtionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-staging-ecmservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-staging-lendingdecisioningservice-app.azurewebsites.net',
    OfferBase: 'https://asapp-staging-offerservice-app.azurewebsites.net',
  },
  'cvcu.portal.staging.asappbanking.com': {
    themeUrl: stagingBrandingBaseUrl,
    apiBase: 'https://asapp-staging-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-staging-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-staging-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-staging-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-staging-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-staging-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'cYTaMblXmgZMso0TthyLV6cbXLYJuPtIm2WfXuPR6tJtAzFuzxX_4A==',
    reportingBase:
      'https://asapp-staging-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-staging-crm-funtionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-staging-ecmservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-staging-lendingdecisioningservice-app.azurewebsites.net',
    OfferBase: 'https://asapp-staging-offerservice-app.azurewebsites.net',
  },
  '1stchoicesavings.portal.staging.asappbanking.com': {
    themeUrl: stagingBrandingBaseUrl,
    apiBase: 'https://asapp-staging-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-staging-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-staging-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-staging-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-staging-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-staging-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'cYTaMblXmgZMso0TthyLV6cbXLYJuPtIm2WfXuPR6tJtAzFuzxX_4A==',
    reportingBase:
      'https://asapp-staging-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-staging-crm-funtionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-staging-ecmservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-staging-lendingdecisioningservice-app.azurewebsites.net',
    OfferBase: 'https://asapp-staging-offerservice-app.azurewebsites.net',
  },
  'leaguedata.ld.portal.staging.asappbanking.com': {
    themeUrl: 'https://ldstgcrmdata.blob.core.windows.net/themes/',
    apiBase: 'https://leaguedata-staging-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://leaguedata-staging-usermanagement-app.azurewebsites.net',
    asappApiService:
      'https://leaguedata-staging-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'c8gh9JXRWmTT8tAcsOHNE4CPN_1xthEOpKpdMb1vYSn3AzFulBy3rQ==',
    reportingBase: '',
    keyVaultBase:
      'https://leaguedata-staging-crm-funtionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://leaguedata-staging-lendingdecisioningservice-app.azurewebsites.net',
    OfferBase: 'https://asapp-staging-offerservice-app.azurewebsites.net',
  },
  'firstontario2.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: '',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: '',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'softude-dev3.portal.qa.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://softude-dev3-crm-app.azurewebsites.net',
    memberBase: 'https://softude-dev3-memberservice-app.azurewebsites.net',
    emailBase: 'https://softude-dev3-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://softude-dev3-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://softude-dev3-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://softude-dev3-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://softude-dev3-crm-functionapp.azurewebsites.net/api/azurekeyvaultfunc',
    azureFunctionKey:
      'jA7MH9m_e1KEQJA4TZza4x6A59FNCWeNEfJlOYd8lL1LAzFuMURXiA==',
    ecmBase: 'https://softude-dev3-ecmservice-app.azurewebsites.net',
    reportingBase:
      'https://softude-dev3-reportingservice-app.azurewebsites.net',
    versioningBase: 'https://asapp-dev-versionservice-app.azurewebsites.net',
  },
  'asapp.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: defaultApiBase,
    memberBase: defaultMemberBase,
    emailBase: defaultEmailBase,
    addressSearchBase: defaultAddressSearchBase,
    userManagementBase: defaultCurrentUserManagementBase,
    asappApiService: defaultAsappApiService,
    keyVaultBase: defaultKeyVaultBase,
    reportingBase: defaultReportingBase,
    ecmBase: defaultEcmBase,
    azureFunctionKey:
      'ctHgI9Html5DnW4CdwqLLL/oMj6XkIoDhYt4xZSTGU1nzJc/q/vJ0Q==',
    versioningBase: defaultVersioningBase,
    OfferBase: defaultOfferBase,
  },
  'sudbury.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'khalsa.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'asapp-test-crm-16.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
  },
  'asapp-test-crm-16.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: defaultApiBase,
    memberBase: defaultMemberBase,
    emailBase: defaultEmailBase,
    addressSearchBase: defaultAddressSearchBase,
    userManagementBase: defaultCurrentUserManagementBase,
    asappApiService: defaultAsappApiService,
    keyVaultBase: defaultKeyVaultBase,
    reportingBase: defaultReportingBase,
    ecmBase: defaultEcmBase,
    azureFunctionKey:
      'ctHgI9Html5DnW4CdwqLLL/oMj6XkIoDhYt4xZSTGU1nzJc/q/vJ0Q==',
    versioningBase: defaultVersioningBase,
    OfferBase: defaultOfferBase,
  },
  'wealthview-test-crm-16.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: defaultApiBase,
    memberBase: defaultMemberBase,
    emailBase: defaultEmailBase,
    addressSearchBase: defaultAddressSearchBase,
    userManagementBase: defaultCurrentUserManagementBase,
    asappApiService: defaultAsappApiService,
    keyVaultBase: defaultKeyVaultBase,
    reportingBase: defaultReportingBase,
    ecmBase: defaultEcmBase,
    azureFunctionKey:
      'ctHgI9Html5DnW4CdwqLLL/oMj6XkIoDhYt4xZSTGU1nzJc/q/vJ0Q==',
    versioningBase: defaultVersioningBase,
    OfferBase: defaultOfferBase,
  },
  'cumish-test-crm-16.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: defaultApiBase,
    memberBase: defaultMemberBase,
    emailBase: defaultEmailBase,
    addressSearchBase: defaultAddressSearchBase,
    userManagementBase: defaultCurrentUserManagementBase,
    asappApiService: defaultAsappApiService,
    keyVaultBase: defaultKeyVaultBase,
    reportingBase: defaultReportingBase,
    ecmBase: defaultEcmBase,
    azureFunctionKey:
      'ctHgI9Html5DnW4CdwqLLL/oMj6XkIoDhYt4xZSTGU1nzJc/q/vJ0Q==',
    versioningBase: defaultVersioningBase,
    OfferBase: defaultOfferBase,
  },
  'cumisd-test-crm-16.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: defaultApiBase,
    memberBase: defaultMemberBase,
    emailBase: defaultEmailBase,
    addressSearchBase: defaultAddressSearchBase,
    userManagementBase: defaultCurrentUserManagementBase,
    asappApiService: defaultAsappApiService,
    keyVaultBase: defaultKeyVaultBase,
    reportingBase: defaultReportingBase,
    ecmBase: defaultEcmBase,
    azureFunctionKey:
      'ctHgI9Html5DnW4CdwqLLL/oMj6XkIoDhYt4xZSTGU1nzJc/q/vJ0Q==',
    versioningBase: defaultVersioningBase,
    OfferBase: defaultOfferBase,
  },
  'rfs-test-crm-16.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: defaultApiBase,
    memberBase: defaultMemberBase,
    emailBase: defaultEmailBase,
    addressSearchBase: defaultAddressSearchBase,
    userManagementBase: defaultCurrentUserManagementBase,
    asappApiService: defaultAsappApiService,
    keyVaultBase: defaultKeyVaultBase,
    reportingBase: defaultReportingBase,
    ecmBase: defaultEcmBase,
    azureFunctionKey:
      'ctHgI9Html5DnW4CdwqLLL/oMj6XkIoDhYt4xZSTGU1nzJc/q/vJ0Q==',
    versioningBase: defaultVersioningBase,
    OfferBase: defaultOfferBase,
  },
  'xchange-test-crm-16.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: defaultApiBase,
    memberBase: defaultMemberBase,
    emailBase: defaultEmailBase,
    addressSearchBase: defaultAddressSearchBase,
    userManagementBase: defaultCurrentUserManagementBase,
    asappApiService: defaultAsappApiService,
    keyVaultBase: defaultKeyVaultBase,
    reportingBase: defaultReportingBase,
    ecmBase: defaultEcmBase,
    azureFunctionKey:
      'ctHgI9Html5DnW4CdwqLLL/oMj6XkIoDhYt4xZSTGU1nzJc/q/vJ0Q==',
    versioningBase: defaultVersioningBase,
    OfferBase: defaultOfferBase,
  },
  'mambu-test-crm-16.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: defaultApiBase,
    memberBase: defaultMemberBase,
    emailBase: defaultEmailBase,
    addressSearchBase: defaultAddressSearchBase,
    userManagementBase: defaultCurrentUserManagementBase,
    asappApiService: defaultAsappApiService,
    keyVaultBase: defaultKeyVaultBase,
    reportingBase: defaultReportingBase,
    ecmBase: defaultEcmBase,
    azureFunctionKey:
      'ctHgI9Html5DnW4CdwqLLL/oMj6XkIoDhYt4xZSTGU1nzJc/q/vJ0Q==',
    versioningBase: defaultVersioningBase,
    OfferBase: defaultOfferBase,
  },
  'prospera.portal.qa.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-test-v21-crm-app-hecjcwfue3fbdjg2.canadaeast-01.azurewebsites.net',
    memberBase: 'https://asapp-test-v21-memberservice-app-hkbmb4bfe3hvckff.canadaeast-01.azurewebsites.net',
    emailBase: 'https://asapp-test-v21-emailservice-app-eraseuc3fmbqghes.canadaeast-01.azurewebsites.net',
    addressSearchBase:
      'https://asapp-test-v21-addresssearchservice-app-e6h9g8agf8aga6g2.canadaeast-01.azurewebsites.net',
    userManagementBase:
      'https://asapp-test-v21-usermanagementservice-app-cahzcpcbecd6c4c5.canadaeast-01.azurewebsites.net',
    asappApiService: 'https://asapp-test-v21-asappservice-app-dpemb7fmezdsfrgq.canadaeast-01.azurewebsites.net',
    azureFunctionKey:
      'uH29pRjaZNaQEt5YHdZTgmMJlS6PTz9q3AA8kVbpcAnXAzFuEm8q_w==',
    reportingBase:
      'https://asapp-test-v21-reportingservice-app-huhndubgb5e2guf4.canadaeast-01.azurewebsites.net',
    keyVaultBase:
      'https://asapp-test-v21-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-test-v21-ecmservice-app-amfxdnecdaf0hcer.canadaeast-01.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-test-v21-lendingdecisionservice-app-e0bqdjc5dngtgqac.canadacentral-01.azurewebsites.net',
    // OfferBase: 'https://asapp-test-v21-offerservice-app-fkgzcehhfyhfctd6.canadaeast-01.azurewebsites.net',
  },
  'offers.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: defaultApiBase,
    memberBase: '',
    emailBase: '',
    addressSearchBase: '',
    userManagementBase: defaultCurrentUserManagementBase,
    asappApiService: defaultAsappApiService,
    keyVaultBase: defaultKeyVaultBase,
    reportingBase: '',
    ecmBase: '',
    azureFunctionKey:
      'ctHgI9Html5DnW4CdwqLLL/oMj6XkIoDhYt4xZSTGU1nzJc/q/vJ0Q==',
    versioningBase: defaultVersioningBase,
    OfferBase: defaultOfferBase,
  },
  'portal.khalsacreditunion.ca': {
    themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
    apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-prod-ecmservice-app.azurewebsites.net',
    reportingBase: 'https://asapp-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'tcufinancial.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    //  OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'luminus.portal.staging.asappbanking.com': {
    themeUrl: stagingBrandingBaseUrl,
    apiBase: 'https://asapp-staging-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-staging-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-staging-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-staging-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-staging-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-staging-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'cYTaMblXmgZMso0TthyLV6cbXLYJuPtIm2WfXuPR6tJtAzFuzxX_4A==',
    reportingBase:
      'https://asapp-staging-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-staging-crm-funtionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-staging-ecmservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-staging-lendingdecisioningservice-app.azurewebsites.net',
    OfferBase: 'https://asapp-staging-offerservice-app.azurewebsites.net',
  },
  'khalsa.portal.staging.asappbanking.com': {
    themeUrl: stagingBrandingBaseUrl,
    apiBase: 'https://asapp-staging-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-staging-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-staging-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-staging-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-staging-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-staging-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'cYTaMblXmgZMso0TthyLV6cbXLYJuPtIm2WfXuPR6tJtAzFuzxX_4A==',
    reportingBase:
      'https://asapp-staging-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-staging-crm-funtionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-staging-ecmservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-staging-lendingdecisioningservice-app.azurewebsites.net',
    OfferBase: 'https://asapp-staging-offerservice-app.azurewebsites.net',
  },
  'digitalsavingsintl.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    // OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'asapp-test-17.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-dev2-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-dev2-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-dev2-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-dev2-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-dev2-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-dev2-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'Kwb5h4VldUpvVEatV2TmTZ53C1u94pCyQrTPyvQgwpSzAzFu1A0ouA==',
    reportingBase: 'https://asapp-dev2-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-dev2-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-dev2-ecmservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-dev2-offerservice-app.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-dev2-lendingdecisioningservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
  },
  'cumisd-test-crm-17.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-dev2-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-dev2-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-dev2-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-dev2-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-dev2-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-dev2-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'Kwb5h4VldUpvVEatV2TmTZ53C1u94pCyQrTPyvQgwpSzAzFu1A0ouA==',
    reportingBase: 'https://asapp-dev2-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-dev2-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-dev2-ecmservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-dev2-offerservice-app.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-dev2-lendingdecisioningservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
  },
  'cumish-test-crm-17.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-dev2-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-dev2-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-dev2-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-dev2-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-dev2-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-dev2-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'Kwb5h4VldUpvVEatV2TmTZ53C1u94pCyQrTPyvQgwpSzAzFu1A0ouA==',
    reportingBase: 'https://asapp-dev2-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-dev2-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-dev2-ecmservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-dev2-offerservice-app.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-dev2-lendingdecisioningservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
  },
  'mambu-test-crm-17.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-dev2-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-dev2-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-dev2-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-dev2-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-dev2-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-dev2-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'Kwb5h4VldUpvVEatV2TmTZ53C1u94pCyQrTPyvQgwpSzAzFu1A0ouA==',
    reportingBase: 'https://asapp-dev2-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-dev2-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-dev2-ecmservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-dev2-offerservice-app.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-dev2-lendingdecisioningservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
  },
  'rfs-test-crm-17.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-dev2-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-dev2-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-dev2-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-dev2-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-dev2-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-dev2-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'Kwb5h4VldUpvVEatV2TmTZ53C1u94pCyQrTPyvQgwpSzAzFu1A0ouA==',
    reportingBase: 'https://asapp-dev2-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-dev2-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-dev2-ecmservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-dev2-offerservice-app.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-dev2-lendingdecisioningservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
  },
  'wealthview-test-crm-17.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-dev2-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-dev2-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-dev2-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-dev2-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-dev2-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-dev2-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'Kwb5h4VldUpvVEatV2TmTZ53C1u94pCyQrTPyvQgwpSzAzFu1A0ouA==',
    reportingBase: 'https://asapp-dev2-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-dev2-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-dev2-ecmservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-dev2-offerservice-app.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-dev2-lendingdecisioningservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
  },
  'xchange-test-crm-17.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-dev2-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-dev2-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-dev2-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-dev2-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-dev2-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-dev2-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'Kwb5h4VldUpvVEatV2TmTZ53C1u94pCyQrTPyvQgwpSzAzFu1A0ouA==',
    reportingBase: 'https://asapp-dev2-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-dev2-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-dev2-ecmservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-dev2-offerservice-app.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-dev2-lendingdecisioningservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
  },
  'crossroadscu.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    // OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'cua-dna.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    // OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'asapp-test-crm-17.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-dev2-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-dev2-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-dev2-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-dev2-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-dev2-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-dev2-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'Kwb5h4VldUpvVEatV2TmTZ53C1u94pCyQrTPyvQgwpSzAzFu1A0ouA==',
    reportingBase: 'https://asapp-dev2-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-dev2-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-dev2-ecmservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    //  OfferBase: 'https://asapp-dev2-offerservice-app.azurewebsites.net',
  },
  'cumisd-test-17.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-dev2-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-dev2-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-dev2-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-dev2-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-dev2-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-dev2-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'Kwb5h4VldUpvVEatV2TmTZ53C1u94pCyQrTPyvQgwpSzAzFu1A0ouA==',
    reportingBase: 'https://asapp-dev2-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-dev2-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-dev2-ecmservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-dev2-offerservice-app.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-dev2-lendingdecisioningservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
  },
  'cumish-test-17.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-dev2-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-dev2-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-dev2-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-dev2-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-dev2-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-dev2-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'Kwb5h4VldUpvVEatV2TmTZ53C1u94pCyQrTPyvQgwpSzAzFu1A0ouA==',
    reportingBase: 'https://asapp-dev2-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-dev2-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-dev2-ecmservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-dev2-offerservice-app.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-dev2-lendingdecisioningservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
  },
  'mambu-test-17.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-dev2-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-dev2-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-dev2-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-dev2-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-dev2-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-dev2-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'Kwb5h4VldUpvVEatV2TmTZ53C1u94pCyQrTPyvQgwpSzAzFu1A0ouA==',
    reportingBase: 'https://asapp-dev2-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-dev2-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-dev2-ecmservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-dev2-offerservice-app.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-dev2-lendingdecisioningservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
  },
  'rfs-test-17.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-dev2-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-dev2-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-dev2-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-dev2-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-dev2-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-dev2-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'Kwb5h4VldUpvVEatV2TmTZ53C1u94pCyQrTPyvQgwpSzAzFu1A0ouA==',
    reportingBase: 'https://asapp-dev2-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-dev2-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-dev2-ecmservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-dev2-offerservice-app.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-dev2-lendingdecisioningservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
  },
  'xchange-test-17.portal.dev.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-dev2-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-dev2-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-dev2-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-dev2-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-dev2-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-dev2-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'Kwb5h4VldUpvVEatV2TmTZ53C1u94pCyQrTPyvQgwpSzAzFu1A0ouA==',
    reportingBase: 'https://asapp-dev2-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-dev2-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-dev2-ecmservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-dev2-offerservice-app.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-dev2-lendingdecisioningservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
  },
  'cumisrfs.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    // OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'cumis.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    // OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'cumisd.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    // OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'cumish.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    // OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'base-site.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    // OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'digitalsaving.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    // OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  },
  'duca.portal.qa.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-test-v21-crm-app-hecjcwfue3fbdjg2.canadaeast-01.azurewebsites.net',
    memberBase: 'https://asapp-test-v21-memberservice-app-hkbmb4bfe3hvckff.canadaeast-01.azurewebsites.net',
    emailBase: 'https://asapp-test-v21-emailservice-app-eraseuc3fmbqghes.canadaeast-01.azurewebsites.net',
    addressSearchBase:
      'https://asapp-test-v21-addresssearchservice-app-e6h9g8agf8aga6g2.canadaeast-01.azurewebsites.net',
    userManagementBase:
      'https://asapp-test-v21-usermanagementservice-app-cahzcpcbecd6c4c5.canadaeast-01.azurewebsites.net',
    asappApiService: 'https://asapp-test-v21-asappservice-app-dpemb7fmezdsfrgq.canadaeast-01.azurewebsites.net',
    azureFunctionKey:
      'uH29pRjaZNaQEt5YHdZTgmMJlS6PTz9q3AA8kVbpcAnXAzFuEm8q_w==',
    reportingBase:
      'https://asapp-test-v21-reportingservice-app-huhndubgb5e2guf4.canadaeast-01.azurewebsites.net',
    keyVaultBase:
      'https://asapp-test-v21-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-test-v21-ecmservice-app-amfxdnecdaf0hcer.canadaeast-01.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-test-v21-lendingdecisionservice-app-e0bqdjc5dngtgqac.canadacentral-01.azurewebsites.net',
    // OfferBase: 'https://asapp-test-v21-offerservice-app-fkgzcehhfyhfctd6.canadaeast-01.azurewebsites.net',
  },
  'engage.tcufinancialgroup.com': {
    themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
    apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-prod-ecmservice-app.azurewebsites.net',
    reportingBase: 'https://asapp-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-prod-lendingdecisioningservice-app.azurewebsites.net',
    //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'tcufinancial.portal.staging.asappbanking.com': {
    themeUrl: stagingBrandingBaseUrl,
    apiBase: 'https://asapp-staging-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-staging-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-staging-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-staging-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-staging-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-staging-asappservice-app.azurewebsites.net',
    azureFunctionKey:
      'cYTaMblXmgZMso0TthyLV6cbXLYJuPtIm2WfXuPR6tJtAzFuzxX_4A==',
    reportingBase:
      'https://asapp-staging-reportingservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-staging-crm-funtionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-staging-ecmservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-staging-lendingdecisioningservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-staging-offerservice-app.azurewebsites.net',
 },
 'asapp.v18.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv18.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v18-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-test-v18-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-test-v18-emailserice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v18-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v18-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-test-v18-asappservice-app.azurewebsites.net',
  azureFunctionKey:
    'mVkTWweSpY9tjilWS7GXYSX3j_Rtcip3VNiktqiCFxv5AzFuFSo0SQ==',
  reportingBase:
    'https://asapp-test-v18-reportingservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v18-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v18-ecmservice-app.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v18-lendingdecisioningservice-app.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v18-offerservice-app.azurewebsites.net',
},
'cumisd.v18.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv18.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v18-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-test-v18-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-test-v18-emailserice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v18-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v18-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-test-v18-asappservice-app.azurewebsites.net',
  azureFunctionKey:
    'mVkTWweSpY9tjilWS7GXYSX3j_Rtcip3VNiktqiCFxv5AzFuFSo0SQ==',
  reportingBase:
    'https://asapp-test-v18-reportingservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v18-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v18-ecmservice-app.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v18-lendingdecisioningservice-app.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v18-offerservice-app.azurewebsites.net',
},
'cumish.v18.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv18.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v18-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-test-v18-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-test-v18-emailserice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v18-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v18-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-test-v18-asappservice-app.azurewebsites.net',
  azureFunctionKey:
    'mVkTWweSpY9tjilWS7GXYSX3j_Rtcip3VNiktqiCFxv5AzFuFSo0SQ==',
  reportingBase:
    'https://asapp-test-v18-reportingservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v18-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v18-ecmservice-app.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v18-lendingdecisioningservice-app.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v18-offerservice-app.azurewebsites.net',
},
'xchange.v18.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv18.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v18-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-test-v18-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-test-v18-emailserice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v18-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v18-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-test-v18-asappservice-app.azurewebsites.net',
  azureFunctionKey:
    'mVkTWweSpY9tjilWS7GXYSX3j_Rtcip3VNiktqiCFxv5AzFuFSo0SQ==',
  reportingBase:
    'https://asapp-test-v18-reportingservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v18-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v18-ecmservice-app.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v18-lendingdecisioningservice-app.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v18-offerservice-app.azurewebsites.net',
},
'rfs.v18.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv18.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v18-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-test-v18-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-test-v18-emailserice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v18-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v18-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-test-v18-asappservice-app.azurewebsites.net',
  azureFunctionKey:
    'mVkTWweSpY9tjilWS7GXYSX3j_Rtcip3VNiktqiCFxv5AzFuFSo0SQ==',
  reportingBase:
    'https://asapp-test-v18-reportingservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v18-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v18-ecmservice-app.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v18-lendingdecisioningservice-app.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v18-offerservice-app.azurewebsites.net',
},
'mambu.v18.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv18.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v18-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-test-v18-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-test-v18-emailserice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v18-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v18-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-test-v18-asappservice-app.azurewebsites.net',
  azureFunctionKey:
    'mVkTWweSpY9tjilWS7GXYSX3j_Rtcip3VNiktqiCFxv5AzFuFSo0SQ==',
  reportingBase:
    'https://asapp-test-v18-reportingservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v18-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v18-ecmservice-app.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v18-lendingdecisioningservice-app.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v18-offerservice-app.azurewebsites.net',
},
'wealthview.v18.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv18.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v18-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-test-v18-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-test-v18-emailserice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v18-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v18-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-test-v18-asappservice-app.azurewebsites.net',
  azureFunctionKey:
    'mVkTWweSpY9tjilWS7GXYSX3j_Rtcip3VNiktqiCFxv5AzFuFSo0SQ==',
  reportingBase:
    'https://asapp-test-v18-reportingservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v18-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v18-ecmservice-app.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v18-lendingdecisioningservice-app.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v18-offerservice-app.azurewebsites.net',
},
'asapp.v19.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv19.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v19-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-test-v19-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-test-v19-emailservice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v19-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v19-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-test-v19-asappservice-app.azurewebsites.net',
  azureFunctionKey:
    'CtRX2MHPmnHmWbDXLggbJI2vpybwnhsGLUzQzcqyx06aAzFuF8G3Bg==',
  reportingBase:
    'https://asapp-test-v19-reportingservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v19-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v19-ecmservice-app.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v19-lendingdecisioningservice-app.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v19-offerservice-app.azurewebsites.net',
},
'cumisd.v19.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv19.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v19-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-test-v19-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-test-v19-emailservice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v19-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v19-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-test-v19-asappservice-app.azurewebsites.net',
  azureFunctionKey:
    'CtRX2MHPmnHmWbDXLggbJI2vpybwnhsGLUzQzcqyx06aAzFuF8G3Bg==',
  reportingBase:
    'https://asapp-test-v19-reportingservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v19-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v19-ecmservice-app.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v19-lendingdecisioningservice-app.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v19-offerservice-app.azurewebsites.net',
},
'cumish.v19.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv19.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v19-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-test-v19-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-test-v19-emailservice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v19-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v19-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-test-v19-asappservice-app.azurewebsites.net',
  azureFunctionKey:
    'CtRX2MHPmnHmWbDXLggbJI2vpybwnhsGLUzQzcqyx06aAzFuF8G3Bg==',
  reportingBase:
    'https://asapp-test-v19-reportingservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v19-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v19-ecmservice-app.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v19-lendingdecisioningservice-app.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v19-offerservice-app.azurewebsites.net',
},
'xchange.v19.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv19.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v19-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-test-v19-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-test-v19-emailservice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v19-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v19-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-test-v19-asappservice-app.azurewebsites.net',
  azureFunctionKey:
    'CtRX2MHPmnHmWbDXLggbJI2vpybwnhsGLUzQzcqyx06aAzFuF8G3Bg==',
  reportingBase:
    'https://asapp-test-v19-reportingservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v19-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v19-ecmservice-app.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v19-lendingdecisioningservice-app.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v19-offerservice-app.azurewebsites.net',
},
'rfs.v19.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv19.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v19-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-test-v19-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-test-v19-emailservice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v19-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v19-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-test-v19-asappservice-app.azurewebsites.net',
  azureFunctionKey:
    'CtRX2MHPmnHmWbDXLggbJI2vpybwnhsGLUzQzcqyx06aAzFuF8G3Bg==',
  reportingBase:
    'https://asapp-test-v19-reportingservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v19-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v19-ecmservice-app.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v19-lendingdecisioningservice-app.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v19-offerservice-app.azurewebsites.net',
},
'mambu.v19.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv19.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v19-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-test-v19-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-test-v19-emailservice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v19-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v19-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-test-v19-asappservice-app.azurewebsites.net',
  azureFunctionKey:
    'CtRX2MHPmnHmWbDXLggbJI2vpybwnhsGLUzQzcqyx06aAzFuF8G3Bg==',
  reportingBase:
    'https://asapp-test-v19-reportingservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v19-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v19-ecmservice-app.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v19-lendingdecisioningservice-app.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v19-offerservice-app.azurewebsites.net',
},
'wealthview.v19.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv19.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v19-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-test-v19-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-test-v19-emailservice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v19-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v19-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-test-v19-asappservice-app.azurewebsites.net',
  azureFunctionKey:
    'CtRX2MHPmnHmWbDXLggbJI2vpybwnhsGLUzQzcqyx06aAzFuF8G3Bg==',
  reportingBase:
    'https://asapp-test-v19-reportingservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v19-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v19-ecmservice-app.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v19-lendingdecisioningservice-app.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v19-offerservice-app.azurewebsites.net',
},
'engage.crossroadscu.ca': {
  themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-prod-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-prod-emailservice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-prod-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-prod-asappservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-prod-ecmservice-app.azurewebsites.net',
  reportingBase: 'https://asapp-prod-reportingservice-app.azurewebsites.net',
  azureFunctionKey:
    '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-prod-lendingdecisioningservice-app.azurewebsites.net',
  //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
},
'engage.cua.com': {
  themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-prod-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-prod-emailservice-app.azurewebsites.net',
  addressSearchBase:
    'https://asapp-prod-addresssearchservice-app.azurewebsites.net',
  userManagementBase:
    'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
  asappApiService: 'https://asapp-prod-asappservice-app.azurewebsites.net',
  keyVaultBase:
    'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-prod-ecmservice-app.azurewebsites.net',
  reportingBase: 'https://asapp-prod-reportingservice-app.azurewebsites.net',
  azureFunctionKey:
    '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-prod-lendingdecisioningservice-app.azurewebsites.net',
  //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
},
'oldengage.cua.com': {
  themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
  memberBase: '',
  emailBase: '',
  addressSearchBase: '',
  userManagementBase:
    'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
  asappApiService:
    'https://asapp-prod-asappservice-app.azurewebsites.net',
  azureFunctionKey:
    '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
  reportingBase: '',
  keyVaultBase:
    'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: '',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://leaguedata-prod-lendingdecisioningservice-app.azurewebsites.net',
  //  OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
},
'mambu.v20.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv20.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v20-crm-app-gedjgycqhkcgg9ey.canadaeast-01.azurewebsites.net',
  memberBase: 'https://asapp-test-v20-memberservice-app-azafcycnehbdb9d4.canadaeast-01.azurewebsites.net',
  emailBase: 'https://asapp-test-v20-emailservice-app-cygwegdmgqd8dfds.canadaeast-01.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v20-addresssearchservice-app-eda6cghhfpcdbjbu.canadaeast-01.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v20-usermanagementservice-app-a3fqg0fsfaarend0.canadaeast-01.azurewebsites.net',
  asappApiService: 'https://asapp-test-v20-asappservice-app-hzbxg4hqh2dbdwh2.canadaeast-01.azurewebsites.net',
  azureFunctionKey:
    'qdeMKwMoTm_96Io87vSb_jBh6nb6T4gg9tzAfnXXwVhcAzFu19uYMA==',
  reportingBase:
    'https://asapp-test-v20-reportingservice-app-fdfkfshhdef3fshq.canadaeast-01.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v20-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v20-ecmservice-app-fydmhtefgbgrh7ar.canadaeast-01.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v20-lendingdecisionservice-app-hzeecsc6baeza3e6.canadaeast-01.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v20-offerservice-app-ebbrhvgnencugzfz.canadaeast-01.azurewebsites.net',
},
'cumish.v20.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv20.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v20-crm-app-gedjgycqhkcgg9ey.canadaeast-01.azurewebsites.net',
  memberBase: 'https://asapp-test-v20-memberservice-app-azafcycnehbdb9d4.canadaeast-01.azurewebsites.net',
  emailBase: 'https://asapp-test-v20-emailservice-app-cygwegdmgqd8dfds.canadaeast-01.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v20-addresssearchservice-app-eda6cghhfpcdbjbu.canadaeast-01.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v20-usermanagementservice-app-a3fqg0fsfaarend0.canadaeast-01.azurewebsites.net',
  asappApiService: 'https://asapp-test-v20-asappservice-app-hzbxg4hqh2dbdwh2.canadaeast-01.azurewebsites.net',
  azureFunctionKey:
    'qdeMKwMoTm_96Io87vSb_jBh6nb6T4gg9tzAfnXXwVhcAzFu19uYMA==',
  reportingBase:
    'https://asapp-test-v20-reportingservice-app-fdfkfshhdef3fshq.canadaeast-01.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v20-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v20-ecmservice-app-fydmhtefgbgrh7ar.canadaeast-01.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v20-lendingdecisionservice-app-hzeecsc6baeza3e6.canadaeast-01.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v20-offerservice-app-ebbrhvgnencugzfz.canadaeast-01.azurewebsites.net',
},
'cumisd.v20.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv20.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v20-crm-app-gedjgycqhkcgg9ey.canadaeast-01.azurewebsites.net',
  memberBase: 'https://asapp-test-v20-memberservice-app-azafcycnehbdb9d4.canadaeast-01.azurewebsites.net',
  emailBase: 'https://asapp-test-v20-emailservice-app-cygwegdmgqd8dfds.canadaeast-01.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v20-addresssearchservice-app-eda6cghhfpcdbjbu.canadaeast-01.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v20-usermanagementservice-app-a3fqg0fsfaarend0.canadaeast-01.azurewebsites.net',
  asappApiService: 'https://asapp-test-v20-asappservice-app-hzbxg4hqh2dbdwh2.canadaeast-01.azurewebsites.net',
  azureFunctionKey:
    'qdeMKwMoTm_96Io87vSb_jBh6nb6T4gg9tzAfnXXwVhcAzFu19uYMA==',
  reportingBase:
    'https://asapp-test-v20-reportingservice-app-fdfkfshhdef3fshq.canadaeast-01.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v20-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v20-ecmservice-app-fydmhtefgbgrh7ar.canadaeast-01.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v20-lendingdecisionservice-app-hzeecsc6baeza3e6.canadaeast-01.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v20-offerservice-app-ebbrhvgnencugzfz.canadaeast-01.azurewebsites.net',
},
'asapp.v20.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv20.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v20-crm-app-gedjgycqhkcgg9ey.canadaeast-01.azurewebsites.net',
  memberBase: 'https://asapp-test-v20-memberservice-app-azafcycnehbdb9d4.canadaeast-01.azurewebsites.net',
  emailBase: 'https://asapp-test-v20-emailservice-app-cygwegdmgqd8dfds.canadaeast-01.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v20-addresssearchservice-app-eda6cghhfpcdbjbu.canadaeast-01.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v20-usermanagementservice-app-a3fqg0fsfaarend0.canadaeast-01.azurewebsites.net',
  asappApiService: 'https://asapp-test-v20-asappservice-app-hzbxg4hqh2dbdwh2.canadaeast-01.azurewebsites.net',
  azureFunctionKey:
    'qdeMKwMoTm_96Io87vSb_jBh6nb6T4gg9tzAfnXXwVhcAzFu19uYMA==',
  reportingBase:
    'https://asapp-test-v20-reportingservice-app-fdfkfshhdef3fshq.canadaeast-01.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v20-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v20-ecmservice-app-fydmhtefgbgrh7ar.canadaeast-01.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v20-lendingdecisionservice-app-hzeecsc6baeza3e6.canadaeast-01.azurewebsites.net',
  OfferBase: 'https://asapp-test-v20-offerservice-app-ebbrhvgnencugzfz.canadaeast-01.azurewebsites.net',
},
'rfs.v20.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv20.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v20-crm-app-gedjgycqhkcgg9ey.canadaeast-01.azurewebsites.net',
  memberBase: 'https://asapp-test-v20-memberservice-app-azafcycnehbdb9d4.canadaeast-01.azurewebsites.net',
  emailBase: 'https://asapp-test-v20-emailservice-app-cygwegdmgqd8dfds.canadaeast-01.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v20-addresssearchservice-app-eda6cghhfpcdbjbu.canadaeast-01.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v20-usermanagementservice-app-a3fqg0fsfaarend0.canadaeast-01.azurewebsites.net',
  asappApiService: 'https://asapp-test-v20-asappservice-app-hzbxg4hqh2dbdwh2.canadaeast-01.azurewebsites.net',
  azureFunctionKey:
    'qdeMKwMoTm_96Io87vSb_jBh6nb6T4gg9tzAfnXXwVhcAzFu19uYMA==',
  reportingBase:
    'https://asapp-test-v20-reportingservice-app-fdfkfshhdef3fshq.canadaeast-01.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v20-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v20-ecmservice-app-fydmhtefgbgrh7ar.canadaeast-01.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v20-lendingdecisionservice-app-hzeecsc6baeza3e6.canadaeast-01.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v20-offerservice-app-ebbrhvgnencugzfz.canadaeast-01.azurewebsites.net',
},
'wealthview.v20.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv20.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v20-crm-app-gedjgycqhkcgg9ey.canadaeast-01.azurewebsites.net',
  memberBase: 'https://asapp-test-v20-memberservice-app-azafcycnehbdb9d4.canadaeast-01.azurewebsites.net',
  emailBase: 'https://asapp-test-v20-emailservice-app-cygwegdmgqd8dfds.canadaeast-01.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v20-addresssearchservice-app-eda6cghhfpcdbjbu.canadaeast-01.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v20-usermanagementservice-app-a3fqg0fsfaarend0.canadaeast-01.azurewebsites.net',
  asappApiService: 'https://asapp-test-v20-asappservice-app-hzbxg4hqh2dbdwh2.canadaeast-01.azurewebsites.net',
  azureFunctionKey:
    'qdeMKwMoTm_96Io87vSb_jBh6nb6T4gg9tzAfnXXwVhcAzFu19uYMA==',
  reportingBase:
    'https://asapp-test-v20-reportingservice-app-fdfkfshhdef3fshq.canadaeast-01.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v20-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v20-ecmservice-app-fydmhtefgbgrh7ar.canadaeast-01.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v20-lendingdecisionservice-app-hzeecsc6baeza3e6.canadaeast-01.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v20-offerservice-app-ebbrhvgnencugzfz.canadaeast-01.azurewebsites.net',
},
'xchange.v20.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdevv20.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v20-crm-app-gedjgycqhkcgg9ey.canadaeast-01.azurewebsites.net',
  memberBase: 'https://asapp-test-v20-memberservice-app-azafcycnehbdb9d4.canadaeast-01.azurewebsites.net',
  emailBase: 'https://asapp-test-v20-emailservice-app-cygwegdmgqd8dfds.canadaeast-01.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v20-addresssearchservice-app-eda6cghhfpcdbjbu.canadaeast-01.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v20-usermanagementservice-app-a3fqg0fsfaarend0.canadaeast-01.azurewebsites.net',
  asappApiService: 'https://asapp-test-v20-asappservice-app-hzbxg4hqh2dbdwh2.canadaeast-01.azurewebsites.net',
  azureFunctionKey:
    'qdeMKwMoTm_96Io87vSb_jBh6nb6T4gg9tzAfnXXwVhcAzFu19uYMA==',
  reportingBase:
    'https://asapp-test-v20-reportingservice-app-fdfkfshhdef3fshq.canadaeast-01.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v20-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v20-ecmservice-app-fydmhtefgbgrh7ar.canadaeast-01.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v20-lendingdecisionservice-app-hzeecsc6baeza3e6.canadaeast-01.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v20-offerservice-app-ebbrhvgnencugzfz.canadaeast-01.azurewebsites.net',
},
'northernsavings.portal.uat.asappbanking.com': {
  themeUrl: uatBrandingBaseUrl,
  apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
  memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
  emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
  addressSearchBase:
    'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
  userManagementBase:
    'https://asappusermanagementserviceuat.azurewebsites.net',
  asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
  keyVaultBase:
    'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  azureFunctionKey:
    'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
  ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
  reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
  LeandingDecisioningBase:
    'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
},
'mambu.v21.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdev21.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v21-crm-app-hecjcwfue3fbdjg2.canadaeast-01.azurewebsites.net',
  memberBase: 'https://asapp-test-v21-memberservice-app-hkbmb4bfe3hvckff.canadaeast-01.azurewebsites.net',
  emailBase: 'https://asapp-test-v21-emailservice-app-eraseuc3fmbqghes.canadaeast-01.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v21-addresssearchservice-app-e6h9g8agf8aga6g2.canadaeast-01.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v21-usermanagementservice-app-cahzcpcbecd6c4c5.canadaeast-01.azurewebsites.net',
  asappApiService: 'https://asapp-test-v21-asappservice-app-dpemb7fmezdsfrgq.canadaeast-01.azurewebsites.net',
  azureFunctionKey:
    'uH29pRjaZNaQEt5YHdZTgmMJlS6PTz9q3AA8kVbpcAnXAzFuEm8q_w==',
  reportingBase:
    'https://asapp-test-v21-reportingservice-app-huhndubgb5e2guf4.canadaeast-01.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v21-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v21-ecmservice-app-amfxdnecdaf0hcer.canadaeast-01.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v21-lendingdecisionservice-app-e0bqdjc5dngtgqac.canadacentral-01.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v21-offerservice-app-fkgzcehhfyhfctd6.canadaeast-01.azurewebsites.net',
},
'cumish.v21.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdev21.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v21-crm-app-hecjcwfue3fbdjg2.canadaeast-01.azurewebsites.net',
  memberBase: 'https://asapp-test-v21-memberservice-app-hkbmb4bfe3hvckff.canadaeast-01.azurewebsites.net',
  emailBase: 'https://asapp-test-v21-emailservice-app-eraseuc3fmbqghes.canadaeast-01.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v21-addresssearchservice-app-e6h9g8agf8aga6g2.canadaeast-01.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v21-usermanagementservice-app-cahzcpcbecd6c4c5.canadaeast-01.azurewebsites.net',
  asappApiService: 'https://asapp-test-v21-asappservice-app-dpemb7fmezdsfrgq.canadaeast-01.azurewebsites.net',
  azureFunctionKey:
    'uH29pRjaZNaQEt5YHdZTgmMJlS6PTz9q3AA8kVbpcAnXAzFuEm8q_w==',
  reportingBase:
    'https://asapp-test-v21-reportingservice-app-huhndubgb5e2guf4.canadaeast-01.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v21-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v21-ecmservice-app-amfxdnecdaf0hcer.canadaeast-01.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v21-lendingdecisionservice-app-e0bqdjc5dngtgqac.canadacentral-01.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v21-offerservice-app-fkgzcehhfyhfctd6.canadaeast-01.azurewebsites.net',
},
'cumisd.v21.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdev21.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v21-crm-app-hecjcwfue3fbdjg2.canadaeast-01.azurewebsites.net',
  memberBase: 'https://asapp-test-v21-memberservice-app-hkbmb4bfe3hvckff.canadaeast-01.azurewebsites.net',
  emailBase: 'https://asapp-test-v21-emailservice-app-eraseuc3fmbqghes.canadaeast-01.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v21-addresssearchservice-app-e6h9g8agf8aga6g2.canadaeast-01.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v21-usermanagementservice-app-cahzcpcbecd6c4c5.canadaeast-01.azurewebsites.net',
  asappApiService: 'https://asapp-test-v21-asappservice-app-dpemb7fmezdsfrgq.canadaeast-01.azurewebsites.net',
  azureFunctionKey:
    'uH29pRjaZNaQEt5YHdZTgmMJlS6PTz9q3AA8kVbpcAnXAzFuEm8q_w==',
  reportingBase:
    'https://asapp-test-v21-reportingservice-app-huhndubgb5e2guf4.canadaeast-01.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v21-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v21-ecmservice-app-amfxdnecdaf0hcer.canadaeast-01.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v21-lendingdecisionservice-app-e0bqdjc5dngtgqac.canadacentral-01.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v21-offerservice-app-fkgzcehhfyhfctd6.canadaeast-01.azurewebsites.net',
},
'asapp.v21.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdev21.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v21-crm-app-hecjcwfue3fbdjg2.canadaeast-01.azurewebsites.net',
  memberBase: 'https://asapp-test-v21-memberservice-app-hkbmb4bfe3hvckff.canadaeast-01.azurewebsites.net',
  emailBase: 'https://asapp-test-v21-emailservice-app-eraseuc3fmbqghes.canadaeast-01.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v21-addresssearchservice-app-e6h9g8agf8aga6g2.canadaeast-01.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v21-usermanagementservice-app-cahzcpcbecd6c4c5.canadaeast-01.azurewebsites.net',
  asappApiService: 'https://asapp-test-v21-asappservice-app-dpemb7fmezdsfrgq.canadaeast-01.azurewebsites.net',
  azureFunctionKey:
    'uH29pRjaZNaQEt5YHdZTgmMJlS6PTz9q3AA8kVbpcAnXAzFuEm8q_w==',
  reportingBase:
    'https://asapp-test-v21-reportingservice-app-huhndubgb5e2guf4.canadaeast-01.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v21-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v21-ecmservice-app-amfxdnecdaf0hcer.canadaeast-01.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v21-lendingdecisionservice-app-e0bqdjc5dngtgqac.canadacentral-01.azurewebsites.net',
  OfferBase: 'https://asapp-test-v21-offerservice-app-fkgzcehhfyhfctd6.canadaeast-01.azurewebsites.net',
},
'rfs.v21.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdev21.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v21-crm-app-hecjcwfue3fbdjg2.canadaeast-01.azurewebsites.net',
  memberBase: 'https://asapp-test-v21-memberservice-app-hkbmb4bfe3hvckff.canadaeast-01.azurewebsites.net',
  emailBase: 'https://asapp-test-v21-emailservice-app-eraseuc3fmbqghes.canadaeast-01.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v21-addresssearchservice-app-e6h9g8agf8aga6g2.canadaeast-01.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v21-usermanagementservice-app-cahzcpcbecd6c4c5.canadaeast-01.azurewebsites.net',
  asappApiService: 'https://asapp-test-v21-asappservice-app-dpemb7fmezdsfrgq.canadaeast-01.azurewebsites.net',
  azureFunctionKey:
    'uH29pRjaZNaQEt5YHdZTgmMJlS6PTz9q3AA8kVbpcAnXAzFuEm8q_w==',
  reportingBase:
    'https://asapp-test-v21-reportingservice-app-huhndubgb5e2guf4.canadaeast-01.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v21-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v21-ecmservice-app-amfxdnecdaf0hcer.canadaeast-01.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v21-lendingdecisionservice-app-e0bqdjc5dngtgqac.canadacentral-01.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v21-offerservice-app-fkgzcehhfyhfctd6.canadaeast-01.azurewebsites.net',
},
'wealthview.v21.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdev21.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v21-crm-app-hecjcwfue3fbdjg2.canadaeast-01.azurewebsites.net',
  memberBase: 'https://asapp-test-v21-memberservice-app-hkbmb4bfe3hvckff.canadaeast-01.azurewebsites.net',
  emailBase: 'https://asapp-test-v21-emailservice-app-eraseuc3fmbqghes.canadaeast-01.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v21-addresssearchservice-app-e6h9g8agf8aga6g2.canadaeast-01.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v21-usermanagementservice-app-cahzcpcbecd6c4c5.canadaeast-01.azurewebsites.net',
  asappApiService: 'https://asapp-test-v21-asappservice-app-dpemb7fmezdsfrgq.canadaeast-01.azurewebsites.net',
  azureFunctionKey:
    'uH29pRjaZNaQEt5YHdZTgmMJlS6PTz9q3AA8kVbpcAnXAzFuEm8q_w==',
  reportingBase:
    'https://asapp-test-v21-reportingservice-app-huhndubgb5e2guf4.canadaeast-01.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v21-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v21-ecmservice-app-amfxdnecdaf0hcer.canadaeast-01.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v21-lendingdecisionservice-app-e0bqdjc5dngtgqac.canadacentral-01.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v21-offerservice-app-fkgzcehhfyhfctd6.canadaeast-01.azurewebsites.net',
},
'xchange.v21.portal.test.asappbanking.com': {
  themeUrl: 'https://asappdev21.blob.core.windows.net/themes/',
  apiBase: 'https://asapp-test-v21-crm-app-hecjcwfue3fbdjg2.canadaeast-01.azurewebsites.net',
  memberBase: 'https://asapp-test-v21-memberservice-app-hkbmb4bfe3hvckff.canadaeast-01.azurewebsites.net',
  emailBase: 'https://asapp-test-v21-emailservice-app-eraseuc3fmbqghes.canadaeast-01.azurewebsites.net',
  addressSearchBase:
    'https://asapp-test-v21-addresssearchservice-app-e6h9g8agf8aga6g2.canadaeast-01.azurewebsites.net',
  userManagementBase:
    'https://asapp-test-v21-usermanagementservice-app-cahzcpcbecd6c4c5.canadaeast-01.azurewebsites.net',
  asappApiService: 'https://asapp-test-v21-asappservice-app-dpemb7fmezdsfrgq.canadaeast-01.azurewebsites.net',
  azureFunctionKey:
    'uH29pRjaZNaQEt5YHdZTgmMJlS6PTz9q3AA8kVbpcAnXAzFuEm8q_w==',
  reportingBase:
    'https://asapp-test-v21-reportingservice-app-huhndubgb5e2guf4.canadaeast-01.azurewebsites.net',
  keyVaultBase:
    'https://asapp-test-v21-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
  ecmBase: 'https://asapp-test-v21-ecmservice-app-amfxdnecdaf0hcer.canadaeast-01.azurewebsites.net',
  versioningBase: defaultVersioningBase,
  LeandingDecisioningBase:
    'https://asapp-test-v21-lendingdecisionservice-app-e0bqdjc5dngtgqac.canadacentral-01.azurewebsites.net',
  // OfferBase: 'https://asapp-test-v21-offerservice-app-fkgzcehhfyhfctd6.canadaeast-01.azurewebsites.net',
},
'engage.bcufinancial.com': {
  themeUrl: 'https://asappprodcrmdata.blob.core.windows.net/themes/',
    apiBase: 'https://asapp-prod-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-prod-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-prod-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asapp-prod-addresssearchservice-app.azurewebsites.net',
    userManagementBase:
      'https://asapp-prod-usermanagementservice-app.azurewebsites.net',
    asappApiService: 'https://asapp-prod-asappservice-app.azurewebsites.net',
    keyVaultBase:
      'https://asapp-prod-crm-functionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    ecmBase: 'https://asapp-prod-ecmservice-app.azurewebsites.net',
    reportingBase: 'https://asapp-prod-reportingservice-app.azurewebsites.net',
    azureFunctionKey:
      '1-_x6SRzashuhCXFJZQ8K8d-eEp8J_w0cYoYVe-DASJxAzFulzNUcA==',
    versioningBase: defaultVersioningBase,
    LeandingDecisioningBase:
      'https://asapp-prod-lendingdecisioningservice-app.azurewebsites.net',
    // OfferBase: 'https://asapp-prod-offerservice-app.azurewebsites.net',
  },
  'digitalsandbox.portal.uat.asappbanking.com': {
    themeUrl: uatBrandingBaseUrl,
    apiBase: 'https://asapp-uat-crm-app.azurewebsites.net',
    memberBase: 'https://asapp-uat-memberservice-app.azurewebsites.net',
    emailBase: 'https://asapp-uat-emailservice-app.azurewebsites.net',
    addressSearchBase:
      'https://asappcrmaddresssearchserviceuat.azurewebsites.net',
    userManagementBase:
      'https://asappusermanagementserviceuat.azurewebsites.net',
    asappApiService: 'https://asappapiserviceuat.azurewebsites.net',
    keyVaultBase:
      'https://asapp-uat-crm-funtctionapp.azurewebsites.net/api/AzureKeyVaultFunc',
    azureFunctionKey:
      'a66UGfWrqwE9syBYk-K1oOFCwwe11nOZK-eINQ03FL64AzFuoadwFg==',
    ecmBase: 'https://asappecmserviceuat.azurewebsites.net',
    reportingBase: 'https://asapp-uat-reportingservice-app.azurewebsites.net',
    versioningBase: defaultVersioningBase,
    // OfferBase: 'https://asapp-uat-offerservice.azurewebsites.net',
    LeandingDecisioningBase:
      'https://asapp-uat-lendingdecisioningservice-app.azurewebsites.net',
  }
};

export function getCurrentApiBase(): string {
  return org[window.location.hostname].apiBase;
}

export function getCurrentMemberBase(): string {
  return org[window.location.hostname].memberBase;
}

export function getCurrentEmailBase(): string {
  return org[window.location.hostname].emailBase;
}

export function getCurrentAosBase(): string {
  return localStorage.getItem('aos_url');
}

export function getCurrentAddressSearchBase(): string {
  return org[window.location.hostname].addressSearchBase;
}

export function getCurrentUserManagementBase(): string {
  return org[window.location.hostname].userManagementBase;
}

export function getKeyVaultBase(): string {
  return org[window.location.hostname].keyVaultBase;
}

export function getReportBase(): string {
  return org[window.location.hostname].reportingBase;
}

export function getEcmBase(): string {
  return org[window.location.hostname].ecmBase;
}

export function getCurrentAsappApiBase(): string {
  return org[window.location.hostname].asappApiService;
}

export function getCurrentThemeUrl(): string {
  return `${org[window.location.hostname].themeUrl}${getClientId()}`;
}

export function getCurrentAzureFunctionKey(): string {
  return org[window.location.hostname].azureFunctionKey;
}

export function getIsCrmRole(): boolean {
  const roles = JSON.parse(localStorage.getItem('roles')) as string[];
  return roles.some((r) => r === RoleEnum.CrmAdmin || r === RoleEnum.CrmUser);
}

export function getClientId(): string {
  return getClientToken().extension_ClientId;
}

export function getSiteId(): string {
  return (getClientToken().extension_SiteAccess as string).toLowerCase();
}

export function getIsAosRole(): boolean {
  const roles = JSON.parse(localStorage.getItem('roles')) as RoleEnum[];
  return !!roles.some((r) => !CRM_ROLES.includes(r));
}

export function getVersioningBase(): string {
  return org[window.location.hostname].versioningBase;
}

export function getOfferApiBase(): string {
  return org[window.location.hostname].OfferBase;
}

export function getLendingDecisioningApiBase(): string {
  return org[window.location.hostname].LeandingDecisioningBase;
}
